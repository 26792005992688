// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file web/public/credit_line.proto (package web.public, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Error } from "./error_pb.js";
import { AutoPayStatus, CardPayment, PaymentMethod, PaymentOption } from "./payment_methods_pb.js";
import { AutoPay as AutoPay$1 } from "./auto_pay_pb.js";
import { Transaction } from "./lending_pb.js";

/**
 * @generated from message web.public.PayUpfrontRequest
 */
export class PayUpfrontRequest extends Message<PayUpfrontRequest> {
  /**
   * @generated from field: string payment_method_token = 1;
   */
  paymentMethodToken = "";

  /**
   * @generated from field: string product_token = 2;
   */
  productToken = "";

  constructor(data?: PartialMessage<PayUpfrontRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PayUpfrontRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PayUpfrontRequest {
    return new PayUpfrontRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PayUpfrontRequest {
    return new PayUpfrontRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PayUpfrontRequest {
    return new PayUpfrontRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PayUpfrontRequest | PlainMessage<PayUpfrontRequest> | undefined, b: PayUpfrontRequest | PlainMessage<PayUpfrontRequest> | undefined): boolean {
    return proto3.util.equals(PayUpfrontRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PayUpfrontResponse
 */
export class PayUpfrontResponse extends Message<PayUpfrontResponse> {
  /**
   * @generated from field: web.public.PayUpfrontResponse.Status status = 1;
   */
  status = PayUpfrontResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.PaymentMethod wallet_payment_method = 3;
   */
  walletPaymentMethod?: PaymentMethod;

  constructor(data?: PartialMessage<PayUpfrontResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PayUpfrontResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(PayUpfrontResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "wallet_payment_method", kind: "message", T: PaymentMethod },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PayUpfrontResponse {
    return new PayUpfrontResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PayUpfrontResponse {
    return new PayUpfrontResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PayUpfrontResponse {
    return new PayUpfrontResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PayUpfrontResponse | PlainMessage<PayUpfrontResponse> | undefined, b: PayUpfrontResponse | PlainMessage<PayUpfrontResponse> | undefined): boolean {
    return proto3.util.equals(PayUpfrontResponse, a, b);
  }

  static _enums: {
    Status: typeof PayUpfrontResponse.Status,
  };
}

export namespace PayUpfrontResponse {
  /**
   * @generated from enum web.public.PayUpfrontResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PayUpfrontResponse.Status)
  proto3.util.setEnumType(PayUpfrontResponse.Status, "web.public.PayUpfrontResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.UpdateCreditLineSettingsRequest
 */
export class UpdateCreditLineSettingsRequest extends Message<UpdateCreditLineSettingsRequest> {
  /**
   * @generated from field: string credit_line_token = 1;
   */
  creditLineToken = "";

  /**
   * @generated from field: web.public.UpdateCreditLineSettingsRequest.AutoPay auto_pay_legacy = 2 [deprecated = true];
   * @deprecated
   */
  autoPayLegacy?: UpdateCreditLineSettingsRequest.AutoPay;

  /**
   * @generated from field: web.public.AutoPay.Settings auto_pay = 3;
   */
  autoPay?: AutoPay$1.Settings;

  constructor(data?: PartialMessage<UpdateCreditLineSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateCreditLineSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_line_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "auto_pay_legacy", kind: "message", T: UpdateCreditLineSettingsRequest.AutoPay },
    { no: 3, name: "auto_pay", kind: "message", T: AutoPay$1.Settings },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCreditLineSettingsRequest {
    return new UpdateCreditLineSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsRequest {
    return new UpdateCreditLineSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsRequest {
    return new UpdateCreditLineSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCreditLineSettingsRequest | PlainMessage<UpdateCreditLineSettingsRequest> | undefined, b: UpdateCreditLineSettingsRequest | PlainMessage<UpdateCreditLineSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCreditLineSettingsRequest, a, b);
  }

  static _enums: {
  };
}

export namespace UpdateCreditLineSettingsRequest {
  /**
   * @generated from message web.public.UpdateCreditLineSettingsRequest.AutoPay
   */
  export class AutoPay extends Message<UpdateCreditLineSettingsRequest.AutoPay> {
    /**
     * @generated from field: bool enabled = 1;
     */
    enabled = false;

    /**
     * @generated from field: string payment_method_token = 2;
     */
    paymentMethodToken = "";

    constructor(data?: PartialMessage<UpdateCreditLineSettingsRequest.AutoPay>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.UpdateCreditLineSettingsRequest.AutoPay";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 2, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCreditLineSettingsRequest.AutoPay {
      return new UpdateCreditLineSettingsRequest.AutoPay().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsRequest.AutoPay {
      return new UpdateCreditLineSettingsRequest.AutoPay().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsRequest.AutoPay {
      return new UpdateCreditLineSettingsRequest.AutoPay().fromJsonString(jsonString, options);
    }

    static equals(a: UpdateCreditLineSettingsRequest.AutoPay | PlainMessage<UpdateCreditLineSettingsRequest.AutoPay> | undefined, b: UpdateCreditLineSettingsRequest.AutoPay | PlainMessage<UpdateCreditLineSettingsRequest.AutoPay> | undefined): boolean {
      return proto3.util.equals(UpdateCreditLineSettingsRequest.AutoPay, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.UpdateCreditLineSettingsResponse
 */
export class UpdateCreditLineSettingsResponse extends Message<UpdateCreditLineSettingsResponse> {
  /**
   * @generated from field: web.public.UpdateCreditLineSettingsResponse.Status status = 1;
   */
  status = UpdateCreditLineSettingsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<UpdateCreditLineSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.UpdateCreditLineSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(UpdateCreditLineSettingsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCreditLineSettingsResponse {
    return new UpdateCreditLineSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsResponse {
    return new UpdateCreditLineSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCreditLineSettingsResponse {
    return new UpdateCreditLineSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCreditLineSettingsResponse | PlainMessage<UpdateCreditLineSettingsResponse> | undefined, b: UpdateCreditLineSettingsResponse | PlainMessage<UpdateCreditLineSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCreditLineSettingsResponse, a, b);
  }

  static _enums: {
    Status: typeof UpdateCreditLineSettingsResponse.Status,
  };
}

export namespace UpdateCreditLineSettingsResponse {
  /**
   * @generated from enum web.public.UpdateCreditLineSettingsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UpdateCreditLineSettingsResponse.Status)
  proto3.util.setEnumType(UpdateCreditLineSettingsResponse.Status, "web.public.UpdateCreditLineSettingsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.InitiatePaymentRequest
 */
export class InitiatePaymentRequest extends Message<InitiatePaymentRequest> {
  /**
   * @generated from field: uint32 amount_cents = 1;
   */
  amountCents = 0;

  /**
   * @generated from field: string credit_line_token = 2;
   */
  creditLineToken = "";

  constructor(data?: PartialMessage<InitiatePaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiatePaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "credit_line_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePaymentRequest {
    return new InitiatePaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePaymentRequest {
    return new InitiatePaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePaymentRequest {
    return new InitiatePaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePaymentRequest | PlainMessage<InitiatePaymentRequest> | undefined, b: InitiatePaymentRequest | PlainMessage<InitiatePaymentRequest> | undefined): boolean {
    return proto3.util.equals(InitiatePaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.InitiatePaymentResponse
 */
export class InitiatePaymentResponse extends Message<InitiatePaymentResponse> {
  /**
   * @generated from field: web.public.InitiatePaymentResponse.Status status = 1;
   */
  status = InitiatePaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.CardPayment card_payment = 3;
   */
  cardPayment?: CardPayment;

  constructor(data?: PartialMessage<InitiatePaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.InitiatePaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(InitiatePaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "card_payment", kind: "message", T: CardPayment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePaymentResponse {
    return new InitiatePaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePaymentResponse {
    return new InitiatePaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePaymentResponse {
    return new InitiatePaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePaymentResponse | PlainMessage<InitiatePaymentResponse> | undefined, b: InitiatePaymentResponse | PlainMessage<InitiatePaymentResponse> | undefined): boolean {
    return proto3.util.equals(InitiatePaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof InitiatePaymentResponse.Status,
  };
}

export namespace InitiatePaymentResponse {
  /**
   * @generated from enum web.public.InitiatePaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(InitiatePaymentResponse.Status)
  proto3.util.setEnumType(InitiatePaymentResponse.Status, "web.public.InitiatePaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.ConfirmPaymentRequest
 */
export class ConfirmPaymentRequest extends Message<ConfirmPaymentRequest> {
  /**
   * @generated from field: string payment_intent_id = 1;
   */
  paymentIntentId = "";

  constructor(data?: PartialMessage<ConfirmPaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ConfirmPaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmPaymentRequest {
    return new ConfirmPaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmPaymentRequest {
    return new ConfirmPaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmPaymentRequest {
    return new ConfirmPaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmPaymentRequest | PlainMessage<ConfirmPaymentRequest> | undefined, b: ConfirmPaymentRequest | PlainMessage<ConfirmPaymentRequest> | undefined): boolean {
    return proto3.util.equals(ConfirmPaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ConfirmPaymentResponse
 */
export class ConfirmPaymentResponse extends Message<ConfirmPaymentResponse> {
  /**
   * @generated from field: web.public.ConfirmPaymentResponse.Status status = 1;
   */
  status = ConfirmPaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<ConfirmPaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ConfirmPaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ConfirmPaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfirmPaymentResponse {
    return new ConfirmPaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfirmPaymentResponse {
    return new ConfirmPaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfirmPaymentResponse {
    return new ConfirmPaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ConfirmPaymentResponse | PlainMessage<ConfirmPaymentResponse> | undefined, b: ConfirmPaymentResponse | PlainMessage<ConfirmPaymentResponse> | undefined): boolean {
    return proto3.util.equals(ConfirmPaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof ConfirmPaymentResponse.Status,
  };
}

export namespace ConfirmPaymentResponse {
  /**
   * @generated from enum web.public.ConfirmPaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ConfirmPaymentResponse.Status)
  proto3.util.setEnumType(ConfirmPaymentResponse.Status, "web.public.ConfirmPaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.MakeCreditLinePaymentRequest
 */
export class MakeCreditLinePaymentRequest extends Message<MakeCreditLinePaymentRequest> {
  /**
   * @generated from field: uint32 amount_cents = 1;
   */
  amountCents = 0;

  /**
   * @generated from field: string credit_line_token = 2;
   */
  creditLineToken = "";

  /**
   * @generated from field: string payment_method_token = 3;
   */
  paymentMethodToken = "";

  /**
   * @generated from field: optional string discount_token = 4;
   */
  discountToken?: string;

  constructor(data?: PartialMessage<MakeCreditLinePaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakeCreditLinePaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "credit_line_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "discount_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakeCreditLinePaymentRequest {
    return new MakeCreditLinePaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakeCreditLinePaymentRequest {
    return new MakeCreditLinePaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakeCreditLinePaymentRequest {
    return new MakeCreditLinePaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MakeCreditLinePaymentRequest | PlainMessage<MakeCreditLinePaymentRequest> | undefined, b: MakeCreditLinePaymentRequest | PlainMessage<MakeCreditLinePaymentRequest> | undefined): boolean {
    return proto3.util.equals(MakeCreditLinePaymentRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.MakeCreditLinePaymentResponse
 */
export class MakeCreditLinePaymentResponse extends Message<MakeCreditLinePaymentResponse> {
  /**
   * @generated from field: web.public.MakeCreditLinePaymentResponse.Status status = 1;
   */
  status = MakeCreditLinePaymentResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<MakeCreditLinePaymentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.MakeCreditLinePaymentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(MakeCreditLinePaymentResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MakeCreditLinePaymentResponse {
    return new MakeCreditLinePaymentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MakeCreditLinePaymentResponse {
    return new MakeCreditLinePaymentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MakeCreditLinePaymentResponse {
    return new MakeCreditLinePaymentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MakeCreditLinePaymentResponse | PlainMessage<MakeCreditLinePaymentResponse> | undefined, b: MakeCreditLinePaymentResponse | PlainMessage<MakeCreditLinePaymentResponse> | undefined): boolean {
    return proto3.util.equals(MakeCreditLinePaymentResponse, a, b);
  }

  static _enums: {
    Status: typeof MakeCreditLinePaymentResponse.Status,
  };
}

export namespace MakeCreditLinePaymentResponse {
  /**
   * @generated from enum web.public.MakeCreditLinePaymentResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FAILED = 1;
     */
    FAILED = 1,

    /**
     * @generated from enum value: SUCCESS = 2;
     */
    SUCCESS = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(MakeCreditLinePaymentResponse.Status)
  proto3.util.setEnumType(MakeCreditLinePaymentResponse.Status, "web.public.MakeCreditLinePaymentResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "SUCCESS" },
  ]);
}

/**
 * @generated from message web.public.Statement
 */
export class Statement extends Message<Statement> {
  /**
   * @generated from field: repeated web.public.Transaction transactions = 1;
   */
  transactions: Transaction[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_at = 2;
   */
  startAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_at = 3;
   */
  endAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp due_at = 4;
   */
  dueAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp minimum_paid_at = 5;
   */
  minimumPaidAt?: Timestamp;

  /**
   * @generated from field: string token = 7;
   */
  token = "";

  /**
   * @generated from field: int32 starting_balance_cents = 8;
   */
  startingBalanceCents = 0;

  /**
   * @generated from field: int32 ending_balance_cents = 9;
   */
  endingBalanceCents = 0;

  /**
   * @generated from field: uint32 minimum_payment_cents = 10;
   */
  minimumPaymentCents = 0;

  /**
   * @generated from field: uint32 credited_cents = 11;
   */
  creditedCents = 0;

  /**
   * @generated from field: uint32 debited_cents = 12;
   */
  debitedCents = 0;

  /**
   * @generated from field: uint32 credit_limit_cents = 13;
   */
  creditLimitCents = 0;

  /**
   * @generated from field: uint32 past_due_amount_cents = 14;
   */
  pastDueAmountCents = 0;

  /**
   * @generated from field: uint32 months_to_pay_back = 15;
   */
  monthsToPayBack = 0;

  /**
   * @generated from field: string url = 18;
   */
  url = "";

  constructor(data?: PartialMessage<Statement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Statement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 2, name: "start_at", kind: "message", T: Timestamp },
    { no: 3, name: "end_at", kind: "message", T: Timestamp },
    { no: 4, name: "due_at", kind: "message", T: Timestamp },
    { no: 5, name: "minimum_paid_at", kind: "message", T: Timestamp },
    { no: 7, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "starting_balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "ending_balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "minimum_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 11, name: "credited_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 12, name: "debited_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 13, name: "credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 14, name: "past_due_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 15, name: "months_to_pay_back", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 18, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Statement {
    return new Statement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Statement {
    return new Statement().fromJsonString(jsonString, options);
  }

  static equals(a: Statement | PlainMessage<Statement> | undefined, b: Statement | PlainMessage<Statement> | undefined): boolean {
    return proto3.util.equals(Statement, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ListStatementsRequest
 */
export class ListStatementsRequest extends Message<ListStatementsRequest> {
  constructor(data?: PartialMessage<ListStatementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListStatementsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStatementsRequest {
    return new ListStatementsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStatementsRequest {
    return new ListStatementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStatementsRequest {
    return new ListStatementsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListStatementsRequest | PlainMessage<ListStatementsRequest> | undefined, b: ListStatementsRequest | PlainMessage<ListStatementsRequest> | undefined): boolean {
    return proto3.util.equals(ListStatementsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ListStatementsResponse
 */
export class ListStatementsResponse extends Message<ListStatementsResponse> {
  /**
   * @generated from field: web.public.ListStatementsResponse.Status status = 1;
   */
  status = ListStatementsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.Statement statements = 3;
   */
  statements: Statement[] = [];

  constructor(data?: PartialMessage<ListStatementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListStatementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ListStatementsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "statements", kind: "message", T: Statement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListStatementsResponse {
    return new ListStatementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListStatementsResponse {
    return new ListStatementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListStatementsResponse {
    return new ListStatementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListStatementsResponse | PlainMessage<ListStatementsResponse> | undefined, b: ListStatementsResponse | PlainMessage<ListStatementsResponse> | undefined): boolean {
    return proto3.util.equals(ListStatementsResponse, a, b);
  }

  static _enums: {
    Status: typeof ListStatementsResponse.Status,
  };
}

export namespace ListStatementsResponse {
  /**
   * @generated from enum web.public.ListStatementsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListStatementsResponse.Status)
  proto3.util.setEnumType(ListStatementsResponse.Status, "web.public.ListStatementsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.PaymentSchedule
 */
export class PaymentSchedule extends Message<PaymentSchedule> {
  /**
   * @generated from field: uint32 repayment_term = 1;
   */
  repaymentTerm = 0;

  /**
   * @generated from field: uint32 monthly_payment_cents = 2;
   */
  monthlyPaymentCents = 0;

  /**
   * @generated from field: repeated web.public.PaymentSchedule.Payment payments = 3;
   */
  payments: PaymentSchedule.Payment[] = [];

  constructor(data?: PartialMessage<PaymentSchedule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PaymentSchedule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "repayment_term", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "monthly_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "payments", kind: "message", T: PaymentSchedule.Payment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSchedule {
    return new PaymentSchedule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSchedule {
    return new PaymentSchedule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSchedule {
    return new PaymentSchedule().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentSchedule | PlainMessage<PaymentSchedule> | undefined, b: PaymentSchedule | PlainMessage<PaymentSchedule> | undefined): boolean {
    return proto3.util.equals(PaymentSchedule, a, b);
  }

  static _enums: {
  };
}

export namespace PaymentSchedule {
  /**
   * @generated from message web.public.PaymentSchedule.Payment
   */
  export class Payment extends Message<PaymentSchedule.Payment> {
    /**
     * @generated from field: google.protobuf.Timestamp due_date = 1;
     */
    dueDate?: Timestamp;

    /**
     * @generated from field: uint32 amount_cents = 2;
     */
    amountCents = 0;

    /**
     * @generated from field: google.protobuf.Timestamp close_date = 3;
     */
    closeDate?: Timestamp;

    /**
     * @generated from field: google.protobuf.Timestamp autopayable_date = 4;
     */
    autopayableDate?: Timestamp;

    constructor(data?: PartialMessage<PaymentSchedule.Payment>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.PaymentSchedule.Payment";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "due_date", kind: "message", T: Timestamp },
      { no: 2, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "close_date", kind: "message", T: Timestamp },
      { no: 4, name: "autopayable_date", kind: "message", T: Timestamp },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSchedule.Payment {
      return new PaymentSchedule.Payment().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSchedule.Payment {
      return new PaymentSchedule.Payment().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSchedule.Payment {
      return new PaymentSchedule.Payment().fromJsonString(jsonString, options);
    }

    static equals(a: PaymentSchedule.Payment | PlainMessage<PaymentSchedule.Payment> | undefined, b: PaymentSchedule.Payment | PlainMessage<PaymentSchedule.Payment> | undefined): boolean {
      return proto3.util.equals(PaymentSchedule.Payment, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.CreditLineAgreement
 */
export class CreditLineAgreement extends Message<CreditLineAgreement> {
  /**
   * @generated from field: string agreement_url = 1;
   */
  agreementUrl = "";

  /**
   * @generated from field: uint32 minimum_payment_amount_cents = 2;
   */
  minimumPaymentAmountCents = 0;

  /**
   * @generated from field: uint32 minimum_payment_amount_percents = 3;
   */
  minimumPaymentAmountPercents = 0;

  /**
   * @generated from field: uint32 default_credit_limit_cents = 4;
   */
  defaultCreditLimitCents = 0;

  constructor(data?: PartialMessage<CreditLineAgreement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditLineAgreement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agreement_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "minimum_payment_amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "minimum_payment_amount_percents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "default_credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAgreement {
    return new CreditLineAgreement().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLineAgreement | PlainMessage<CreditLineAgreement> | undefined, b: CreditLineAgreement | PlainMessage<CreditLineAgreement> | undefined): boolean {
    return proto3.util.equals(CreditLineAgreement, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Discount
 */
export class Discount extends Message<Discount> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  /**
   * @generated from field: uint32 amount_cents = 3;
   */
  amountCents = 0;

  /**
   * @generated from field: string percent = 4;
   */
  percent = "";

  constructor(data?: PartialMessage<Discount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Discount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "percent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Discount {
    return new Discount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Discount {
    return new Discount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Discount {
    return new Discount().fromJsonString(jsonString, options);
  }

  static equals(a: Discount | PlainMessage<Discount> | undefined, b: Discount | PlainMessage<Discount> | undefined): boolean {
    return proto3.util.equals(Discount, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.Furnishing
 */
export class Furnishing extends Message<Furnishing> {
  constructor(data?: PartialMessage<Furnishing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.Furnishing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Furnishing {
    return new Furnishing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Furnishing {
    return new Furnishing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Furnishing {
    return new Furnishing().fromJsonString(jsonString, options);
  }

  static equals(a: Furnishing | PlainMessage<Furnishing> | undefined, b: Furnishing | PlainMessage<Furnishing> | undefined): boolean {
    return proto3.util.equals(Furnishing, a, b);
  }

  static _enums: {
    Status: typeof Furnishing.Status,
  };
}

export namespace Furnishing {
  /**
   * @generated from enum web.public.Furnishing.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: FURNISHED = 1;
     */
    FURNISHED = 1,

    /**
     * @generated from enum value: UNFURNISHED = 2;
     */
    UNFURNISHED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(Furnishing.Status)
  proto3.util.setEnumType(Furnishing.Status, "web.public.Furnishing.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "FURNISHED" },
    { no: 2, name: "UNFURNISHED" },
  ]);
}

/**
 * @generated from message web.public.CreditLineAccount
 */
export class CreditLineAccount extends Message<CreditLineAccount> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: uint32 number_of_payments = 2;
   */
  numberOfPayments = 0;

  /**
   * @generated from field: uint32 loan_duration_months = 3;
   */
  loanDurationMonths = 0;

  /**
   * @generated from field: uint32 credit_limit_cents = 4;
   */
  creditLimitCents = 0;

  /**
   * @generated from field: int32 balance_cents = 5;
   */
  balanceCents = 0;

  /**
   * @generated from field: int32 available_balance_cents = 6;
   */
  availableBalanceCents = 0;

  /**
   * @generated from field: uint32 minimum_payment_due_cents = 7;
   */
  minimumPaymentDueCents = 0;

  /**
   * @generated from field: uint32 outstanding_minimum_payment_cents = 8;
   */
  outstandingMinimumPaymentCents = 0;

  /**
   * @generated from field: google.protobuf.Timestamp payment_due_date = 9;
   */
  paymentDueDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp next_statement_closing_date = 10;
   */
  nextStatementClosingDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp opened_at = 11;
   */
  openedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp closed_at = 12;
   */
  closedAt?: Timestamp;

  /**
   * @generated from field: repeated web.public.Transaction transactions = 13;
   */
  transactions: Transaction[] = [];

  /**
   * @generated from field: web.public.PaymentSchedule payment_schedule = 14;
   */
  paymentSchedule?: PaymentSchedule;

  /**
   * @generated from field: repeated web.public.Statement statements = 15;
   */
  statements: Statement[] = [];

  /**
   * @generated from field: uint32 overdue_cents = 16;
   */
  overdueCents = 0;

  /**
   * @generated from field: bool autopay_enabled = 17 [deprecated = true];
   * @deprecated
   */
  autopayEnabled = false;

  /**
   * @generated from field: string body_description = 18;
   */
  bodyDescription = "";

  /**
   * @generated from field: bool autopay_succeeded = 19 [deprecated = true];
   * @deprecated
   */
  autopaySucceeded = false;

  /**
   * @generated from field: bool allow_closure = 20;
   */
  allowClosure = false;

  /**
   * @generated from field: web.public.CreditLineAgreement credit_line_agreement = 21;
   */
  creditLineAgreement?: CreditLineAgreement;

  /**
   * @generated from field: string autopay_payment_method_description = 22 [deprecated = true];
   * @deprecated
   */
  autopayPaymentMethodDescription = "";

  /**
   * @generated from field: repeated web.public.PaymentOption payment_options = 24;
   */
  paymentOptions: PaymentOption[] = [];

  /**
   * @generated from field: string payment_method_token = 25 [deprecated = true];
   * @deprecated
   */
  paymentMethodToken = "";

  /**
   * @generated from field: web.public.AutoPayStatus auto_pay_legacy = 26 [deprecated = true];
   * @deprecated
   */
  autoPayLegacy?: AutoPayStatus;

  /**
   * @generated from field: web.public.Furnishing.Status furnishing_status = 27;
   */
  furnishingStatus = Furnishing.Status.UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp activated_at = 28;
   */
  activatedAt?: Timestamp;

  /**
   * @generated from field: web.public.CreditLineAccount.DelayedPayment delayed_payment = 29;
   */
  delayedPayment?: CreditLineAccount.DelayedPayment;

  /**
   * @generated from field: uint32 current_statement_credit_applied_cents = 30;
   */
  currentStatementCreditAppliedCents = 0;

  /**
   * @generated from field: uint32 next_statement_credit_cents = 31;
   */
  nextStatementCreditCents = 0;

  /**
   * @generated from field: web.public.AutoPay auto_pay = 32;
   */
  autoPay?: AutoPay$1;

  /**
   * @generated from field: web.public.CreditLineAgreement credit_line_agreement_on_upgrade = 33;
   */
  creditLineAgreementOnUpgrade?: CreditLineAgreement;

  /**
   * @generated from field: bool max_credit_lines_reached = 37;
   */
  maxCreditLinesReached = false;

  /**
   * @generated from field: bool allow_reactivation = 38;
   */
  allowReactivation = false;

  /**
   * @generated from field: bool allow_pause = 39;
   */
  allowPause = false;

  /**
   * @generated from field: bool paused = 40;
   */
  paused = false;

  /**
   * @generated from field: google.protobuf.Timestamp resume_at = 41;
   */
  resumeAt?: Timestamp;

  /**
   * @generated from field: web.public.CreditLineAccount.CreditLineIncreasePolicy credit_line_increase_policy = 42;
   */
  creditLineIncreasePolicy?: CreditLineAccount.CreditLineIncreasePolicy;

  /**
   * @generated from field: bool money_back_guarantee_eligible = 43;
   */
  moneyBackGuaranteeEligible = false;

  /**
   * @generated from field: repeated web.public.Discount discounts = 44;
   */
  discounts: Discount[] = [];

  /**
   * @generated from field: repeated google.protobuf.Timestamp pausable_dates = 45;
   */
  pausableDates: Timestamp[] = [];

  /**
   * @generated from field: bool charged_off = 46;
   */
  chargedOff = false;

  constructor(data?: PartialMessage<CreditLineAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CreditLineAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "number_of_payments", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "loan_duration_months", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "available_balance_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "minimum_payment_due_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "outstanding_minimum_payment_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "payment_due_date", kind: "message", T: Timestamp },
    { no: 10, name: "next_statement_closing_date", kind: "message", T: Timestamp },
    { no: 11, name: "opened_at", kind: "message", T: Timestamp },
    { no: 12, name: "closed_at", kind: "message", T: Timestamp },
    { no: 13, name: "transactions", kind: "message", T: Transaction, repeated: true },
    { no: 14, name: "payment_schedule", kind: "message", T: PaymentSchedule },
    { no: 15, name: "statements", kind: "message", T: Statement, repeated: true },
    { no: 16, name: "overdue_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 17, name: "autopay_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "body_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "autopay_succeeded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "allow_closure", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "credit_line_agreement", kind: "message", T: CreditLineAgreement },
    { no: 22, name: "autopay_payment_method_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "payment_options", kind: "message", T: PaymentOption, repeated: true },
    { no: 25, name: "payment_method_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "auto_pay_legacy", kind: "message", T: AutoPayStatus },
    { no: 27, name: "furnishing_status", kind: "enum", T: proto3.getEnumType(Furnishing.Status) },
    { no: 28, name: "activated_at", kind: "message", T: Timestamp },
    { no: 29, name: "delayed_payment", kind: "message", T: CreditLineAccount.DelayedPayment },
    { no: 30, name: "current_statement_credit_applied_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 31, name: "next_statement_credit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 32, name: "auto_pay", kind: "message", T: AutoPay$1 },
    { no: 33, name: "credit_line_agreement_on_upgrade", kind: "message", T: CreditLineAgreement },
    { no: 37, name: "max_credit_lines_reached", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 38, name: "allow_reactivation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 39, name: "allow_pause", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 40, name: "paused", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 41, name: "resume_at", kind: "message", T: Timestamp },
    { no: 42, name: "credit_line_increase_policy", kind: "message", T: CreditLineAccount.CreditLineIncreasePolicy },
    { no: 43, name: "money_back_guarantee_eligible", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 44, name: "discounts", kind: "message", T: Discount, repeated: true },
    { no: 45, name: "pausable_dates", kind: "message", T: Timestamp, repeated: true },
    { no: 46, name: "charged_off", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAccount {
    return new CreditLineAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAccount {
    return new CreditLineAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAccount {
    return new CreditLineAccount().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLineAccount | PlainMessage<CreditLineAccount> | undefined, b: CreditLineAccount | PlainMessage<CreditLineAccount> | undefined): boolean {
    return proto3.util.equals(CreditLineAccount, a, b);
  }

  static _enums: {
  };
}

export namespace CreditLineAccount {
  /**
   * @generated from message web.public.CreditLineAccount.DelayedPayment
   */
  export class DelayedPayment extends Message<CreditLineAccount.DelayedPayment> {
    /**
     * @generated from field: web.public.CreditLineAccount.DelayedPayment.Status status = 1;
     */
    status = CreditLineAccount.DelayedPayment.Status.UNKNOWN;

    /**
     * @generated from field: string reason = 2;
     */
    reason = "";

    /**
     * @generated from field: google.protobuf.Timestamp scheduled_at = 3;
     */
    scheduledAt?: Timestamp;

    /**
     * @generated from field: google.protobuf.Timestamp attempted_at = 4;
     */
    attemptedAt?: Timestamp;

    constructor(data?: PartialMessage<CreditLineAccount.DelayedPayment>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditLineAccount.DelayedPayment";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CreditLineAccount.DelayedPayment.Status) },
      { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 3, name: "scheduled_at", kind: "message", T: Timestamp },
      { no: 4, name: "attempted_at", kind: "message", T: Timestamp },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAccount.DelayedPayment {
      return new CreditLineAccount.DelayedPayment().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAccount.DelayedPayment {
      return new CreditLineAccount.DelayedPayment().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAccount.DelayedPayment {
      return new CreditLineAccount.DelayedPayment().fromJsonString(jsonString, options);
    }

    static equals(a: CreditLineAccount.DelayedPayment | PlainMessage<CreditLineAccount.DelayedPayment> | undefined, b: CreditLineAccount.DelayedPayment | PlainMessage<CreditLineAccount.DelayedPayment> | undefined): boolean {
      return proto3.util.equals(CreditLineAccount.DelayedPayment, a, b);
    }

    static _enums: {
      Status: typeof CreditLineAccount.DelayedPayment.Status,
    };
  }
}

export namespace CreditLineAccount.DelayedPayment {
  /**
   * @generated from enum web.public.CreditLineAccount.DelayedPayment.Status
   */
  export enum Status {
    /**
     * Effectively means pending ( last_attempt_at is blank )
     *
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCEEDED = 1;
     */
    SUCCEEDED = 1,

    /**
     * @generated from enum value: SKIPPED = 2;
     */
    SKIPPED = 2,

    /**
     * @generated from enum value: FAILED = 3;
     */
    FAILED = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CreditLineAccount.DelayedPayment.Status)
  proto3.util.setEnumType(CreditLineAccount.DelayedPayment.Status, "web.public.CreditLineAccount.DelayedPayment.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCEEDED" },
    { no: 2, name: "SKIPPED" },
    { no: 3, name: "FAILED" },
  ]);
}

export namespace CreditLineAccount {
  /**
   * @generated from message web.public.CreditLineAccount.CreditLineIncreasePolicy
   */
  export class CreditLineIncreasePolicy extends Message<CreditLineAccount.CreditLineIncreasePolicy> {
    /**
     * @generated from field: string policy_name = 1;
     */
    policyName = "";

    /**
     * @generated from field: uint32 maximum_credit_limit_cents = 2;
     */
    maximumCreditLimitCents = 0;

    /**
     * @generated from field: int32 payment_streak = 3;
     */
    paymentStreak = 0;

    /**
     * @generated from field: int32 allowed_increase = 4;
     */
    allowedIncrease = 0;

    /**
     * @generated from field: repeated web.public.CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth increase_timeline_months = 5;
     */
    increaseTimelineMonths: CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth[] = [];

    constructor(data?: PartialMessage<CreditLineAccount.CreditLineIncreasePolicy>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditLineAccount.CreditLineIncreasePolicy";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "policy_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "maximum_credit_limit_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
      { no: 3, name: "payment_streak", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
      { no: 4, name: "allowed_increase", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
      { no: 5, name: "increase_timeline_months", kind: "message", T: CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth, repeated: true },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAccount.CreditLineIncreasePolicy {
      return new CreditLineAccount.CreditLineIncreasePolicy().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAccount.CreditLineIncreasePolicy {
      return new CreditLineAccount.CreditLineIncreasePolicy().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAccount.CreditLineIncreasePolicy {
      return new CreditLineAccount.CreditLineIncreasePolicy().fromJsonString(jsonString, options);
    }

    static equals(a: CreditLineAccount.CreditLineIncreasePolicy | PlainMessage<CreditLineAccount.CreditLineIncreasePolicy> | undefined, b: CreditLineAccount.CreditLineIncreasePolicy | PlainMessage<CreditLineAccount.CreditLineIncreasePolicy> | undefined): boolean {
      return proto3.util.equals(CreditLineAccount.CreditLineIncreasePolicy, a, b);
    }

    static _enums: {
    };
  }
}

export namespace CreditLineAccount.CreditLineIncreasePolicy {
  /**
   * @generated from message web.public.CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth
   */
  export class IncreaseTimelineMonth extends Message<CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth> {
    /**
     * @generated from field: int32 credit_limit_cents = 2;
     */
    creditLimitCents = 0;

    /**
     * @generated from field: bool is_premium = 5;
     */
    isPremium = false;

    /**
     * @generated from field: string plan_name = 6;
     */
    planName = "";

    constructor(data?: PartialMessage<CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "web.public.CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 2, name: "credit_limit_cents", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
      { no: 5, name: "is_premium", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
      { no: 6, name: "plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth {
      return new CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth {
      return new CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth {
      return new CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth().fromJsonString(jsonString, options);
    }

    static equals(a: CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth | PlainMessage<CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth> | undefined, b: CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth | PlainMessage<CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth> | undefined): boolean {
      return proto3.util.equals(CreditLineAccount.CreditLineIncreasePolicy.IncreaseTimelineMonth, a, b);
    }

    static _enums: {
    };
  }
}

/**
 * @generated from message web.public.ListCreditLineDetailsRequest
 */
export class ListCreditLineDetailsRequest extends Message<ListCreditLineDetailsRequest> {
  constructor(data?: PartialMessage<ListCreditLineDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListCreditLineDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCreditLineDetailsRequest {
    return new ListCreditLineDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCreditLineDetailsRequest {
    return new ListCreditLineDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCreditLineDetailsRequest {
    return new ListCreditLineDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCreditLineDetailsRequest | PlainMessage<ListCreditLineDetailsRequest> | undefined, b: ListCreditLineDetailsRequest | PlainMessage<ListCreditLineDetailsRequest> | undefined): boolean {
    return proto3.util.equals(ListCreditLineDetailsRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ListCreditLineDetailsResponse
 */
export class ListCreditLineDetailsResponse extends Message<ListCreditLineDetailsResponse> {
  /**
   * @generated from field: web.public.ListCreditLineDetailsResponse.Status status = 1;
   */
  status = ListCreditLineDetailsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: web.public.CreditLineAccount credit_line = 3;
   */
  creditLine?: CreditLineAccount;

  constructor(data?: PartialMessage<ListCreditLineDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListCreditLineDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ListCreditLineDetailsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "credit_line", kind: "message", T: CreditLineAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCreditLineDetailsResponse {
    return new ListCreditLineDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCreditLineDetailsResponse {
    return new ListCreditLineDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCreditLineDetailsResponse {
    return new ListCreditLineDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCreditLineDetailsResponse | PlainMessage<ListCreditLineDetailsResponse> | undefined, b: ListCreditLineDetailsResponse | PlainMessage<ListCreditLineDetailsResponse> | undefined): boolean {
    return proto3.util.equals(ListCreditLineDetailsResponse, a, b);
  }

  static _enums: {
    Status: typeof ListCreditLineDetailsResponse.Status,
  };
}

export namespace ListCreditLineDetailsResponse {
  /**
   * @generated from enum web.public.ListCreditLineDetailsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListCreditLineDetailsResponse.Status)
  proto3.util.setEnumType(ListCreditLineDetailsResponse.Status, "web.public.ListCreditLineDetailsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.FetchCreditAgreementRequest
 */
export class FetchCreditAgreementRequest extends Message<FetchCreditAgreementRequest> {
  constructor(data?: PartialMessage<FetchCreditAgreementRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FetchCreditAgreementRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCreditAgreementRequest {
    return new FetchCreditAgreementRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCreditAgreementRequest {
    return new FetchCreditAgreementRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCreditAgreementRequest {
    return new FetchCreditAgreementRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCreditAgreementRequest | PlainMessage<FetchCreditAgreementRequest> | undefined, b: FetchCreditAgreementRequest | PlainMessage<FetchCreditAgreementRequest> | undefined): boolean {
    return proto3.util.equals(FetchCreditAgreementRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.FetchCreditAgreementResponse
 */
export class FetchCreditAgreementResponse extends Message<FetchCreditAgreementResponse> {
  /**
   * @generated from field: string agreement_markdown = 1;
   */
  agreementMarkdown = "";

  /**
   * @generated from field: bool require_identity_confirmation = 2;
   */
  requireIdentityConfirmation = false;

  constructor(data?: PartialMessage<FetchCreditAgreementResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.FetchCreditAgreementResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "agreement_markdown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "require_identity_confirmation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchCreditAgreementResponse {
    return new FetchCreditAgreementResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchCreditAgreementResponse {
    return new FetchCreditAgreementResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchCreditAgreementResponse {
    return new FetchCreditAgreementResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchCreditAgreementResponse | PlainMessage<FetchCreditAgreementResponse> | undefined, b: FetchCreditAgreementResponse | PlainMessage<FetchCreditAgreementResponse> | undefined): boolean {
    return proto3.util.equals(FetchCreditAgreementResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CloseCreditLineRequest
 */
export class CloseCreditLineRequest extends Message<CloseCreditLineRequest> {
  /**
   * @generated from field: string closure_reason = 1;
   */
  closureReason = "";

  /**
   * @generated from field: string credit_line_token = 2;
   */
  creditLineToken = "";

  /**
   * @generated from field: string closure_note = 3;
   */
  closureNote = "";

  constructor(data?: PartialMessage<CloseCreditLineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CloseCreditLineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "closure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "credit_line_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "closure_note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloseCreditLineRequest {
    return new CloseCreditLineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloseCreditLineRequest {
    return new CloseCreditLineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloseCreditLineRequest {
    return new CloseCreditLineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CloseCreditLineRequest | PlainMessage<CloseCreditLineRequest> | undefined, b: CloseCreditLineRequest | PlainMessage<CloseCreditLineRequest> | undefined): boolean {
    return proto3.util.equals(CloseCreditLineRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.CloseCreditLineResponse
 */
export class CloseCreditLineResponse extends Message<CloseCreditLineResponse> {
  /**
   * @generated from field: web.public.CloseCreditLineResponse.Status status = 1;
   */
  status = CloseCreditLineResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: bool will_refund = 3;
   */
  willRefund = false;

  constructor(data?: PartialMessage<CloseCreditLineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.CloseCreditLineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CloseCreditLineResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "will_refund", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloseCreditLineResponse {
    return new CloseCreditLineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloseCreditLineResponse {
    return new CloseCreditLineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloseCreditLineResponse {
    return new CloseCreditLineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CloseCreditLineResponse | PlainMessage<CloseCreditLineResponse> | undefined, b: CloseCreditLineResponse | PlainMessage<CloseCreditLineResponse> | undefined): boolean {
    return proto3.util.equals(CloseCreditLineResponse, a, b);
  }

  static _enums: {
    Status: typeof CloseCreditLineResponse.Status,
  };
}

export namespace CloseCreditLineResponse {
  /**
   * @generated from enum web.public.CloseCreditLineResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(CloseCreditLineResponse.Status)
  proto3.util.setEnumType(CloseCreditLineResponse.Status, "web.public.CloseCreditLineResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.PauseCreditLineRequest
 */
export class PauseCreditLineRequest extends Message<PauseCreditLineRequest> {
  /**
   * @generated from field: string credit_line_token = 1;
   */
  creditLineToken = "";

  /**
   * @generated from field: int32 months = 2;
   */
  months = 0;

  constructor(data?: PartialMessage<PauseCreditLineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PauseCreditLineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_line_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "months", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PauseCreditLineRequest {
    return new PauseCreditLineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PauseCreditLineRequest {
    return new PauseCreditLineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PauseCreditLineRequest {
    return new PauseCreditLineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PauseCreditLineRequest | PlainMessage<PauseCreditLineRequest> | undefined, b: PauseCreditLineRequest | PlainMessage<PauseCreditLineRequest> | undefined): boolean {
    return proto3.util.equals(PauseCreditLineRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.PauseCreditLineResponse
 */
export class PauseCreditLineResponse extends Message<PauseCreditLineResponse> {
  /**
   * @generated from field: web.public.PauseCreditLineResponse.Status status = 1;
   */
  status = PauseCreditLineResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  constructor(data?: PartialMessage<PauseCreditLineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.PauseCreditLineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(PauseCreditLineResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PauseCreditLineResponse {
    return new PauseCreditLineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PauseCreditLineResponse {
    return new PauseCreditLineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PauseCreditLineResponse {
    return new PauseCreditLineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PauseCreditLineResponse | PlainMessage<PauseCreditLineResponse> | undefined, b: PauseCreditLineResponse | PlainMessage<PauseCreditLineResponse> | undefined): boolean {
    return proto3.util.equals(PauseCreditLineResponse, a, b);
  }

  static _enums: {
    Status: typeof PauseCreditLineResponse.Status,
  };
}

export namespace PauseCreditLineResponse {
  /**
   * @generated from enum web.public.PauseCreditLineResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(PauseCreditLineResponse.Status)
  proto3.util.setEnumType(PauseCreditLineResponse.Status, "web.public.PauseCreditLineResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

/**
 * @generated from message web.public.SignedCreditLineAgreement
 */
export class SignedCreditLineAgreement extends Message<SignedCreditLineAgreement> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: google.protobuf.Timestamp signed_at = 2;
   */
  signedAt?: Timestamp;

  constructor(data?: PartialMessage<SignedCreditLineAgreement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.SignedCreditLineAgreement";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "signed_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedCreditLineAgreement {
    return new SignedCreditLineAgreement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedCreditLineAgreement {
    return new SignedCreditLineAgreement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedCreditLineAgreement {
    return new SignedCreditLineAgreement().fromJsonString(jsonString, options);
  }

  static equals(a: SignedCreditLineAgreement | PlainMessage<SignedCreditLineAgreement> | undefined, b: SignedCreditLineAgreement | PlainMessage<SignedCreditLineAgreement> | undefined): boolean {
    return proto3.util.equals(SignedCreditLineAgreement, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message web.public.ListCreditLineAgreementsResponse
 */
export class ListCreditLineAgreementsResponse extends Message<ListCreditLineAgreementsResponse> {
  /**
   * @generated from field: web.public.ListCreditLineAgreementsResponse.Status status = 1;
   */
  status = ListCreditLineAgreementsResponse.Status.UNKNOWN;

  /**
   * @generated from field: web.public.Error error = 2;
   */
  error?: Error;

  /**
   * @generated from field: repeated web.public.SignedCreditLineAgreement signed_credit_line_agreements = 3;
   */
  signedCreditLineAgreements: SignedCreditLineAgreement[] = [];

  constructor(data?: PartialMessage<ListCreditLineAgreementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "web.public.ListCreditLineAgreementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(ListCreditLineAgreementsResponse.Status) },
    { no: 2, name: "error", kind: "message", T: Error },
    { no: 3, name: "signed_credit_line_agreements", kind: "message", T: SignedCreditLineAgreement, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCreditLineAgreementsResponse {
    return new ListCreditLineAgreementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCreditLineAgreementsResponse {
    return new ListCreditLineAgreementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCreditLineAgreementsResponse {
    return new ListCreditLineAgreementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCreditLineAgreementsResponse | PlainMessage<ListCreditLineAgreementsResponse> | undefined, b: ListCreditLineAgreementsResponse | PlainMessage<ListCreditLineAgreementsResponse> | undefined): boolean {
    return proto3.util.equals(ListCreditLineAgreementsResponse, a, b);
  }

  static _enums: {
    Status: typeof ListCreditLineAgreementsResponse.Status,
  };
}

export namespace ListCreditLineAgreementsResponse {
  /**
   * @generated from enum web.public.ListCreditLineAgreementsResponse.Status
   */
  export enum Status {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: SUCCESS = 1;
     */
    SUCCESS = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(ListCreditLineAgreementsResponse.Status)
  proto3.util.setEnumType(ListCreditLineAgreementsResponse.Status, "web.public.ListCreditLineAgreementsResponse.Status", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "SUCCESS" },
    { no: 2, name: "FAILED" },
  ]);
}

