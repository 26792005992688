import Chainable from "./Chainable.mts";
import UFunction from "./UFunction.mts";
import UPromise from "./UPromise.mts";
import UProxy from "./UProxy.mts";
import UTuple from "./UTuple.mts";
import UType from "./UType.mts";

declare global {
  interface ObjectConstructor {
    keys<Obj extends UObject.Any>(obj: Obj): `${Exclude<keyof Obj, symbol>}`[];
    entries<Obj extends UObject.Any>(obj: Obj): UObject.Entry.FromNative<Obj>[];
    fromEntries<const Entry extends readonly [key: UObject.Key, value?: any]>(
      entries: Iterable<Entry>,
    ): UObject.FromEntryUnion<Entry>;
  }
}

namespace UObject {
  export type Any =
    | Record<Key, any>
    | Record<string, any>
    | Record<number, any>
    | Record<symbol, any>;
  export type Empty = Record<Key, never>;
  export type Key = keyof any;

  export namespace Key {
    export type OfUnion<T> = T extends T ? keyof T : never;

    export type FilterByEntry<
      Obj extends Any,
      MatchEntry extends [Key, any],
    > = Value<{
      [Key in keyof Obj]: [Key, Obj[Key]] extends MatchEntry ? Key : never;
    }>;

    export type ForValue<
      Obj extends Any,
      Value extends UObject.Value<Obj>,
    > = UObject.Value<{
      [Key in keyof Obj]: Obj[Key] extends Value ? Key : never;
    }>;
  }
  export type Value<Obj extends Any> = Obj[keyof Obj];
  export namespace Value {
    export type OfUnion<Obj extends Any> = Value<{
      [Key in Key.OfUnion<Obj>]: Extract<Obj, Record<Key, any>>[Key];
    }>;
  }

  export type Entry<Obj extends Any> = Value<{
    [Key in Key.OfUnion<Obj>]: [Key, Extract<Obj, Record<Key, any>>[Key]];
  }>;
  export namespace Entry {
    // From native Object.entries
    export type FromNative<Obj extends Any> = Value<{
      [Key in Key.OfUnion<Obj>]: Key extends symbol
        ? never
        : [`${Exclude<Key, symbol>}`, Extract<Obj, Record<Key, any>>[Key]];
    }>;
  }

  export type GetFromUnion<
    Obj extends Any,
    Key extends Key.OfUnion<Obj>,
  > = Obj extends { [_ in Key]: infer T } ? T : never;

  export namespace Pick {
    export type FromUnion<
      Obj extends Any,
      K extends Key.OfUnion<Obj> | (string & {}),
    > = Obj extends Obj
      ? {
          [Key in K as Key extends keyof Obj ? Key : never]: Obj[UType.Narrow<
            Key,
            keyof Obj
          >];
        }
      : never;
  }
  export const pick = <Obj extends Any, Key extends UObject.Key.OfUnion<Obj>>(
    obj: Obj,
    keys: Key[],
  ): Pick.FromUnion<Obj, Key> => {
    const keySet = new Set(keys);
    return transform(obj, (kv) =>
      keySet.has(kv[0] as Key) ? kv : undefined,
    ) as any;
  };

  export type PartialBy<
    Obj extends Any,
    K extends Key.OfUnion<Obj> | (string & {}),
  > = Omit<Obj, K> & Partial<Pick.FromUnion<Obj, K>>;
  export type PartialExcept<
    Obj extends Any,
    K extends Key.OfUnion<Obj> | (string & {}),
  > = Pick.FromUnion<Obj, K> & Partial<Omit<Obj, K>>;

  export type DeepPartial<T> = {
    [P in keyof T]?: keyof T[P] extends never ? T[P] : DeepPartial<T[P]>;
  };
  export type DeepPartialBy<
    T,
    K extends string | number | symbol,
  > = T extends object
    ? T extends any[]
      ? DeepPartialBy<T[number], K>[]
      : {
          [P in keyof Omit<T, K>]: DeepPartialBy<T[P], K>;
        } & {
          [P in K & keyof T]?: T[P] extends object
            ? T[P] extends any[]
              ? T[P]
              : DeepPartialBy<T[P], K>
            : T[P];
        }
    : T;

  export type FromEntryUnion<T extends readonly [key: Key, value?: any]> = {
    [Key in T[0]]: Extract<T, readonly [Key, any]>[1] extends never
      ? T[1]
      : Extract<T, readonly [Key, any]>[1];
  };

  export type ExpandUnion<T extends Any> = T extends any
    ? Value<{
        [Key in keyof T]: UTuple.FromUnion<T[Key]> extends infer UnionTuple
          ? Value<{
              [I in Exclude<keyof UnionTuple, keyof []>]: Override<
                T,
                UType.Narrow<{ [_ in Key]: UnionTuple[I] }, Partial<T>>
              >;
            }>
          : never;
      }>
    : never;

  export type Path<T extends Any, Prefix extends Key[] = []> =
    // TODO: Improve true type performance, use flexible type until then
    any extends any
      ? UObject.Key[]
      : UType.Default<
          Value<{
            [Key in keyof T]: T[Key] extends Any
              ? T[Key] extends T
                ? UObject.Key[]
                : [...Prefix, Key] | Path<T[Key], [...Prefix, Key]>
              : [...Prefix, Key];
          }>,
          Key[]
        >;
  export type Override<T extends Any, Overrides> = Omit<T, keyof Overrides> &
    Overrides;

  export const from = {
    keys<const Key extends UObject.Key, T>(keys: readonly Key[], value: T) {
      return Object.fromEntries(keys.map((key) => [key, value])) as Record<
        Key,
        T
      >;
    },
  };

  export const is = (maybeObj: any): maybeObj is Any =>
    typeof maybeObj === "object";

  export const entries = <Obj extends Any>(obj: Obj) =>
    Object.entries(obj) as Entry.FromNative<Obj>[];

  // Use if `in` keyword doesn't narrow type properly
  export function hasKey<Obj extends Any, Key extends UObject.Key.OfUnion<Obj>>(
    obj: Obj,
    key: Key,
  ): obj is Obj & { [_ in Key]: any };
  export function hasKey<Obj extends Any, Key extends UObject.Key>(
    obj: Obj,
    key: Key,
  ): key is Key & UObject.Key.OfUnion<Obj>;
  export function hasKey(obj: Any, key: Key) {
    return key in obj;
  }

  export type Select<T, R = any> = keyof T | ((obj: T) => R);
  export declare namespace Select {
    type Subject<S extends Select<any>> = S extends Select<infer T> ? T : never;
    type Result<T, S extends Select<T>> = S extends UFunction.Any
      ? ReturnType<S>
      : S extends keyof T
        ? T[S]
        : never;
  }
  export function createSelector<S extends Select<any>>(
    select: S,
  ): <Obj extends Select.Subject<S>>(
    obj: Obj,
  ) => Select.Result<Obj, S extends Select<Obj, any> ? S : never> {
    if (typeof select !== "function") {
      const key = select;
      // @ts-expect-error Ignore generic constraint
      select = (obj) => obj[key];
    }

    return select as any;
  }

  export function transform<
    Obj extends UObject.Any,
    Result extends readonly [UObject.Key, any],
  >(
    obj: Obj,
    transform: (
      entry: Entry.FromNative<Obj>,
    ) => Result | false | null | undefined,
  ) {
    // TODO: Benchmark against building object with loop
    return Object.fromEntries(
      Object.entries<Value<Obj>>(obj).flatMap((entry) => {
        const res = transform(entry as any);
        return res ? [res] : [];
      }) as any,
    ) as UObject.FromEntryUnion<Result>;
  }
  transform.deep = <Obj extends Any, Result extends [UObject.Key, any]>(
    obj: Obj,
    transform: (
      entry: Entry.FromNative<Obj>,
      next: () => any,
    ) => Result | false | null | undefined,
  ) =>
    (function next(obj: any): any {
      if (!obj || typeof obj !== "object") return obj;
      if (Array.isArray(obj)) return obj.map(next);
      return UObject.transform(obj, ([key, value]) =>
        transform([key, value] as Entry.FromNative<Obj>, () => next(value)),
      );
    })(obj);

  export async function transformAsync<
    Obj extends UObject.Any,
    Result extends readonly [UObject.Key, any],
  >(
    obj: Obj,
    transform: (
      entry: Entry.FromNative<Obj>,
    ) => UPromise.Awaitable<Result | false | null | undefined>,
  ) {
    return Object.fromEntries(
      (
        await Promise.all(
          Object.entries<Value<Obj>>(obj).map(async (entry) => {
            const res = await transform(entry as any);
            return res ? [res] : [];
          }) as any,
        )
      ).flat(),
    ) as Promise<UObject.FromEntryUnion<Result>>;
  }

  export type TrimKeys<
    Obj extends Record<`${Prefix}${string}${Suffix}`, any>,
    Prefix extends string,
    Suffix extends string = "",
  > = string extends keyof Obj
    ? Obj
    : {
        [Key in keyof Obj as Key extends `${Prefix}${infer T}${Suffix}`
          ? T
          : never]: Obj[Key];
      };
  export const trimKeys = <
    Obj extends Record<`${Prefix}${string}${Suffix}`, any>,
    Prefix extends string,
    Suffix extends string = "",
  >(
    obj: Obj,
    prefix: Prefix,
    suffix = "" as Suffix,
  ): TrimKeys<Obj, Prefix, Suffix> =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v]) => {
        if (!k.startsWith(prefix))
          throw new Error(
            `Found key "${k}" that does not start with prefix "${prefix}"`,
          );
        if (!k.endsWith(suffix))
          throw new Error(
            `Found key "${k}" that does not start with suffix "${suffix}"`,
          );

        return [k.slice(prefix.length).slice(0, -suffix.length || Infinity), v];
      }),
    ) as any;

  export const inverse = <const Obj extends Record<Key, Key>>(obj: Obj) =>
    transform(obj, ([key, value]: any) => [value, key]) as {
      [Key in keyof Obj as Obj[Key]]: Key;
    };

  export const omit = <Obj extends UObject.Any, Props extends (keyof Obj)[]>(
    obj: Obj,
    props: Props,
  ): Omit<Obj, Props[number]> =>
    transform(
      obj,
      (
        // TODO: Fix transform type
        [key, value]: any,
      ) => !props.includes(key) && [key, value],
    ) as any;

  export const isPartialOf = <
    Obj extends UObject.Any,
    Partial extends UObject.Any,
  >(
    obj: Obj,
    extended: Partial,
  ): obj is Extract<Obj, Partial> =>
    Object.entries(obj).every(
      ([key, value]) => value === (extended as any)[key],
    );

  export const merge = UProxy.merge;

  export const deepKey = (() => {
    const deepKey = (keyTransform: (key: string) => Key) => {
      const walk = (v: unknown) =>
        v && typeof v === "object" ? deepKey(keyTransform)(v) : v;

      return (obj: UObject.Any): UObject.Any =>
        obj &&
        ((Array.isArray(obj)
          ? obj.map(walk)
          : transform(obj, ([k, v]) => [
              keyTransform(k as string),
              walk(v),
            ])) as UObject.Any);
    };

    return Object.assign(deepKey, {
      toCamel: deepKey((k) => k.replace(/_[a-z]/g, (s) => s[1]!.toUpperCase())),
      toSnake: deepKey((k) =>
        k.replace(/[A-Z]/g, (s) => `_${s.toLowerCase()}`),
      ),
    });
  })();

  export type GetByPath<
    Obj extends UObject.Any,
    Path extends UObject.Path<Obj>,
  > = Path extends [
    infer NextKey extends Key,
    ...infer Rest extends UObject.Key[],
  ]
    ? Obj extends Record<NextKey, infer T>
      ? Rest extends []
        ? T
        : T extends UObject.Any
          ? GetByPath<T, Rest>
          : never
      : never
    : never;

  export const getByPath = <
    Obj extends UObject.Any,
    Path extends UObject.Path<Obj>,
  >(
    obj: Obj,
    path: Path,
  ) =>
    (function next(current: any, i = 0): any {
      if (i >= path.length) return current;
      if (current == null)
        throw new Error(
          `Cannot read property of undefined at path \`${path.slice(0, i).join(".")}\` (reading "${String(path[i])}")`,
        );
      return next(current[(path as any)[i]], i + 1);
    })(obj);

  export const mut = {
    setByPath: Chainable.create(
      Chainable.wrapFactory(
        ({ createParents = false }) =>
          <Obj extends UObject.Any>(
            obj: Obj,
            path: UObject.Path<Obj>,
            value: any,
          ) =>
            (function next(current: any, i = 0) {
              if (!current)
                throw new Error(
                  "Field in path is undefined, if you need to create parents as needed, use setByPath.createParents.",
                );
              const property = (path as any)[i] as any;
              if (i === path.length - 1) {
                current[property] = value;
              } else {
                if (createParents) current[property] ??= {};
                next(current[property], i + 1);
              }
            })(obj),
      ),
      { createParents: { createParents: true } },
    ),
  };
}

export default UObject;
