// DO NOT EDIT: This file is generated
/* eslint-disable */

/* GENERATED */ // prettier-ignore
/* GENERATED */ export const usServices = {
/* GENERATED */   Agreement: () => import("../../gen/web/public/agreement_connect"),
/* GENERATED */   Array: () => import("../../gen/web/public/array_connect"),
/* GENERATED */   AtomicFi: () => import("../../gen/web/public/atomic_fi_connect"),
/* GENERATED */   Auth: () => import("../../gen/web/public/auth_connect"),
/* GENERATED */   Avatar: () => import("../../gen/web/public/avatar_connect"),
/* GENERATED */   BillReporting: () => import("../../gen/web/public/bill_reporting_connect"),
/* GENERATED */   CashAdvance: () => import("../../gen/web/public/cash_advance_connect"),
/* GENERATED */   CashCard: () => import("../../gen/web/public/cash_card_connect"),
/* GENERATED */   Cbs: () => import("../../gen/web/public/cbs_connect"),
/* GENERATED */   Chat: () => import("../../gen/web/public/chat_connect"),
/* GENERATED */   Coaching: () => import("../../gen/web/public/coaching_connect"),
/* GENERATED */   ColumnTax: () => import("../../gen/web/public/column_tax_connect"),
/* GENERATED */   Command: () => import("../../gen/web/public/command_connect"),
/* GENERATED */   Community: () => import("../../gen/web/public/community_connect"),
/* GENERATED */   Config: () => import("../../gen/web/public/config_connect"),
/* GENERATED */   Credit: () => import("../../gen/web/public/credit_connect"),
/* GENERATED */   CreditDisputes: () => import("../../gen/web/public/credit_disputes_connect"),
/* GENERATED */   CreditLine: () => import("../../gen/web/public/credit_line_connect"),
/* GENERATED */   CreditMonitoring: () => import("../../gen/web/public/credit_monitoring_connect"),
/* GENERATED */   DebtSettlement: () => import("../../gen/web/public/debt_settlement_connect"),
/* GENERATED */   Events: () => import("../../gen/web/public/events_connect"),
/* GENERATED */   ExternalBankAccounts: () => import("../../gen/web/public/external_bank_accounts_connect"),
/* GENERATED */   Funds: () => import("../../gen/web/public/funds_connect"),
/* GENERATED */   Galileo: () => import("../../gen/web/public/galileo_connect"),
/* GENERATED */   GoogleSSO: () => import("../../gen/web/public/google_sso_connect"),
/* GENERATED */   Lending: () => import("../../gen/web/public/lending_connect"),
/* GENERATED */   MailingCancellation: () => import("../../gen/web/public/mailing_cancellation_connect"),
/* GENERATED */   Mailing: () => import("../../gen/web/public/mailing_connect"),
/* GENERATED */   Marqeta: () => import("../../gen/web/public/marqeta_connect"),
/* GENERATED */   MVNO: () => import("../../gen/web/public/mvno_connect"),
/* GENERATED */   Negotiations: () => import("../../gen/web/public/negotiations_connect"),
/* GENERATED */   Notifications: () => import("../../gen/web/public/notifications_connect"),
/* GENERATED */   Onboarding: () => import("../../gen/web/public/onboarding_connect"),
/* GENERATED */   PaymentMethods: () => import("../../gen/web/public/payment_methods_connect"),
/* GENERATED */   Personalization: () => import("../../gen/web/public/personalization_connect"),
/* GENERATED */   Pockets: () => import("../../gen/web/public/pockets_connect"),
/* GENERATED */   PostBoarding: () => import("../../gen/web/public/post_onboarding_connect"),
/* GENERATED */   Programs: () => import("../../gen/web/public/programs_connect"),
/* GENERATED */   Promotions: () => import("../../gen/web/public/promotions_connect"),
/* GENERATED */   Reactivation: () => import("../../gen/web/public/reactivation_connect"),
/* GENERATED */   Recommendations: () => import("../../gen/web/public/recommendations_connect"),
/* GENERATED */   Recurring: () => import("../../gen/web/public/recurring_connect"),
/* GENERATED */   Referrals: () => import("../../gen/web/public/referrals_connect"),
/* GENERATED */   RentReporting: () => import("../../gen/web/public/rent_reporting_connect"),
/* GENERATED */   Reverification: () => import("../../gen/web/public/reverification_connect"),
/* GENERATED */   SecuredCard: () => import("../../gen/web/public/secured_card_connect"),
/* GENERATED */   Shopping: () => import("../../gen/web/public/shopping_connect"),
/* GENERATED */   Subscription: () => import("../../gen/web/public/subscription_connect"),
/* GENERATED */   SubscriptionManagement: () => import("../../gen/web/public/subscription_management_connect"),
/* GENERATED */   Support: () => import("../../gen/web/public/support_connect"),
/* GENERATED */   Surveys: () => import("../../gen/web/public/surveys_connect"),
/* GENERATED */   Switcher: () => import("../../gen/web/public/switcher_connect"),
/* GENERATED */   Tipping: () => import("../../gen/web/public/tipping_connect"),
/* GENERATED */   UnsecuredPersonalLoans: () => import("../../gen/web/public/unsecured_personal_loans_connect"),
/* GENERATED */   Upsells: () => import("../../gen/web/public/upsells_connect"),
/* GENERATED */   Account: () => import("../../gen/web/public/user_connect"),
/* GENERATED */   Waitlists: () => import("../../gen/web/public/waitlists_connect"),
/* GENERATED */   YearInReview: () => import("../../gen/web/public/year_in_review_connect"),
/* GENERATED */ };
/* GENERATED */
/* GENERATED */ // prettier-ignore
/* GENERATED */ export const canadaServices = {
/* GENERATED */   AuthService: () => import("../../gen/kikoff_canada/protobuf/services/auth_service_connect"),
/* GENERATED */   CreditLineService: () => import("../../gen/kikoff_canada/protobuf/services/credit_line_service_connect"),
/* GENERATED */   CreditService: () => import("../../gen/kikoff_canada/protobuf/services/credit_service_connect"),
/* GENERATED */   DebugService: () => import("../../gen/kikoff_canada/protobuf/services/debug_service_connect"),
/* GENERATED */   FeatureFlagsService: () => import("../../gen/kikoff_canada/protobuf/services/feature_flags_service_connect"),
/* GENERATED */   OnboardingService: () => import("../../gen/kikoff_canada/protobuf/services/onboarding_service_connect"),
/* GENERATED */   PaymentMethodsService: () => import("../../gen/kikoff_canada/protobuf/services/payment_methods_service_connect"),
/* GENERATED */   ShoppingService: () => import("../../gen/kikoff_canada/protobuf/services/shopping_service_connect"),
/* GENERATED */   SubscriptionService: () => import("../../gen/kikoff_canada/protobuf/services/subscription_service_connect"),
/* GENERATED */   UserService: () => import("../../gen/kikoff_canada/protobuf/services/user_service_connect"),
/* GENERATED */ };
/* GENERATED */
/* GENERATED */ // prettier-ignore
/* GENERATED */ export const canadaAdminServices = {
/* GENERATED */   AuthService: () => import("../../gen/kikoff_canada/protobuf/services/asgard/auth_service_connect"),
/* GENERATED */   CreditLinesService: () => import("../../gen/kikoff_canada/protobuf/services/asgard/credit_lines_service_connect"),
/* GENERATED */   PaymentsService: () => import("../../gen/kikoff_canada/protobuf/services/asgard/payments_service_connect"),
/* GENERATED */   UsersService: () => import("../../gen/kikoff_canada/protobuf/services/asgard/users_service_connect"),
/* GENERATED */ };
/* GENERATED */
/* GENERATED */ // prettier-ignore
/* GENERATED */ export const canadaFrontendServices = {
/* GENERATED */   StorageService: () => import("../../gen/kikoff_canada/protobuf/services/frontend/storage_service_connect"),
/* GENERATED */ };
/* GENERATED */
