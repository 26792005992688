// TODO: Replace with Intl.DurationFormat after good adoption
import { DurationFormat } from "@formatjs/intl-durationformat";

import Chainable from "./Chainable.mts";
import Locale from "./Locale.mts";
import UFunction from "./UFunction.mts";

export const msIn = (() => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  return {
    second,
    minute,
    hour,
    day,
    week,
    month,
    year,
  } as const;
})();

namespace Duration {
  export const precisionOrder = (() => {
    const order = [
      "second",
      "minute",
      "hour",
      "day",
      "week",
      "month",
      "year",
    ] as const;
    return Object.assign(order, {
      reversed: [...order].reverse() as unknown as readonly [
        "year",
        "month",
        "week",
        "day",
        "hour",
        "minute",
        "second",
      ],
    });
  })();

  export type FormatStyle = NonNullable<
    NonNullable<ConstructorParameters<typeof DurationFormat>[1]>["style"]
  >;

  // DurationFormat instance construction is expensive, must cache
  const durationFormatter = UFunction.memo(
    (locale: Locale, style: FormatStyle) =>
      new DurationFormat(locale, { style }),
  );
  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({ maxUnits = Infinity, locale = Locale.current, defaultValue = "0" }) =>
        (ms: number, style: FormatStyle) =>
          ms < msIn.second
            ? defaultValue
            : durationFormatter(locale, style).format(
                Object.fromEntries(
                  precisionOrder.reversed
                    .map((unit) => {
                      // eslint-disable-next-line no-sparse-arrays
                      if (maxUnits === 0) return [, 0];
                      const count = Math.floor(ms / msIn[unit]);
                      if (count) {
                        ms -= count * msIn[unit];
                        --maxUnits;
                      }
                      return [`${unit}s`, count];
                    })
                    .filter(([, count]) => count),
                ),
              ),
    ),
    {
      maxUnits: (maxUnits: number) => ({ maxUnits }),
      locale: (locale: Locale) => ({ locale }),
      default: (defaultValue: string) => ({ defaultValue }),
    },
  );
}

export default Duration;
