import { ComponentProps, JSX } from "solid-js";
import c from "class-c";

import styles from "./ScrollView.module.scss";

declare namespace ScrollView {
  type Props = ComponentProps<"div"> & {
    children: JSX.Element;
  };
}

function ScrollView({
  class: className,
  children,
  ...props
}: D<ScrollView.Props>) {
  return (
    <div class={c`${styles["scroll-view"]} ${className}`} {...props}>
      {children}
    </div>
  );
}

declare namespace ScrollView.Reversed {
  type Props = ScrollView.Props;
}

ScrollView.Reversed = Reversed;
function Reversed({
  class: className,
  children,
  ...props
}: D<ScrollView.Reversed.Props>) {
  return (
    <div class={c`${styles["reversed-scroll-view"]} ${className}`} {...props}>
      <div>{children}</div>
    </div>
  );
}

export default ScrollView;
