import UArray from "./UArray.mts";
import UObject from "./UObject.mts";

namespace Log {
  export let enabled = true;

  export const plain = (...args: any[]) => enabled && console.log(...args);
  export const warn = (...args: any[]) => enabled && console.warn(...args);
  export const error = (...args: any[]) => enabled && console.error(...args);

  export const group =
    (...args: any[]) =>
    (group: () => void) => {
      if (!enabled) return;

      console.groupCollapsed(...resolveConsoleArgs(...args));
      group();
      console.groupEnd();
    };

  export const color = (color: string, content: any) => [
    Log.color,
    color,
    content,
  ];
  export const style = (styles: string, content: any) => [
    Log.style,
    styles,
    content,
  ];
  export const raw = (value: any) => [Log.raw, value];
  export const join = (arr: any[], separator: any) => [
    Log.join,
    arr,
    separator,
  ];
  export const args = (args: any[]) => Log.join(args.map(Log.raw), ", ");
  export const fnPath = (path: (UObject.Key | any[])[]) =>
    Log.join(
      path.map((item, i, { length }) =>
        typeof item === "object"
          ? item
          : Log.color(
              i === length - 1 ? "#fe6" : i === 0 ? "#4af" : "#bef",
              item,
            ),
      ),
      Log.color("grey", "."),
    );
  export const fn = (path: (UObject.Key | any[])[], args: any[]) =>
    Log.join([Log.fnPath(path), "(", Log.args(args), ")"], "");

  export function resolveConsoleArgs(...args: any[]) {
    if (
      !(
        Array.isArray(args[0]) &&
        "raw" in args[0] &&
        Array.isArray(args[0].raw)
      )
    )
      return args;

    const interpolations: any[] = [];
    return [
      UArray.join(args[0], (i) =>
        (function resolve(value = args[i + 1]): string {
          if (typeof value !== "object") return value;
          if (Array.isArray(value)) {
            if (value[0] === Log.color) {
              interpolations.push(`color:${value[1]}`, "");
              return `%c${value[2]}%c`;
            }
            if (value[0] === Log.style) {
              interpolations.push(value[1], "");
              return `%c${value[2]}%c`;
            }
            if (value[0] === Log.join) {
              return UArray.zip(
                value[1],
                Array(Math.max(value[1].length - 1, 0)).fill(value[2]),
              )
                .map(resolve)
                .join("");
            }
            if (value[0] === Log.raw) {
              interpolations.push(value[1]);
              return "%o";
            }
          }
          interpolations.push(value);
          return "%o";
        })(),
      ),
      ...interpolations,
    ];
  }
  export function withTrace<T>(...args: [...any[], T]): T {
    group(...args)(() => {
      console.trace();
    });
    return args.at(-1);
  }
  export function v<T>(...args: [...any[], T]): T {
    plain(...resolveConsoleArgs(...args));
    return args.at(-1);
  }
  export function r<T>(f: () => T): T {
    const res = f();
    plain(f.toString(), "=>", res);
    return res;
  }
}

export default Log;
