import Api from "./Api.mjs";

export default class PendingMigrationError extends Error {
  name = "PendingMigrationError";
  message = "You have pending migrations.";
  server: "rails";

  static rails(message?: string) {
    return new this("rails", message);
  }

  private constructor(
    server: PendingMigrationError["server"],
    message = "You have pending migrations.",
  ) {
    super(message);
    this.server = server;
  }

  fix() {
    if (this.server === "rails") {
      return fetch(`${Api.origin}/rails/actions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "action=Run+pending+migrations&error=ActiveRecord%3A%3APendingMigrationError&location=%2F",
      });
    }
    throw new Error(`Invalid server value ${this.server}`);
  }
}
