import {
  ComponentProps,
  JSX,
  onCleanup,
  onMount,
  Show,
  untrack,
} from "solid-js";
import c from "class-c";
import { Motion, Presence } from "solid-motionone";
import { createBoundSignal } from "solid-signals";
import { PropsFor } from "solid-u";

import UObject from "@repo/utils/UObject";
import UEventTarget from "@repo/utils-client/UEventTarget";
import USolid from "@repo/utils-solid/USolid";

import createDomRect from "../signals/createDomRect";

import styles from "./FloatingWithAnchor.module.scss";

declare namespace FloatingWithAnchor {
  type Props = {
    class?: string;
    anchor: JSX.Element;
    show?: boolean;
    onClickOutside?(): void;
    propsFor?: {
      floating: UObject.Override<
        ComponentProps<"div">,
        { style?: JSX.CSSProperties }
      >;
    };
    position?: "bottom" | "bottom-left";
    children?: JSX.Element;
  };
}

function FloatingWithAnchor({
  class: className,
  anchor,
  position = "bottom",
  show: _show,
  onClickOutside,
  propsFor: _propsFor,
  children,
}: D<FloatingWithAnchor.Props>) {
  const resolvedAnchor = USolid.children(() => anchor);
  const propsFor = PropsFor.createHandler(() => _propsFor);
  const [show, setShow] = createBoundSignal(false, [
    untrack(() => _show === undefined) ? undefined : () => _show!,
  ]);

  onMount(() => {
    const anchorElement = resolvedAnchor();
    if (anchorElement instanceof Array)
      throw new Error("`anchor` must only have one element.");

    if (!(anchorElement instanceof HTMLElement))
      // In order to use createDomRect to track positioning
      throw new Error("`anchor` must be instance of HTMLElement.");

    onCleanup(
      UEventTarget.wrap(anchorElement).on("click", () => {
        if (show()) return;
        setTimeout(() => {
          setShow(true);
        });
      }).off,
    );
  });

  const rect = createDomRect();

  rect.ref(resolvedAnchor() as any);

  let menuRef: HTMLDivElement;
  onMount(() => {
    onCleanup(
      UEventTarget.wrap(document.body).on("click", (e: MouseEvent) => {
        if (menuRef && !menuRef.contains(e.target as Node)) {
          if (_show == null && show()) setShow(false);
          onClickOutside?.();
        }
      }).off,
    );
  });

  return (
    <>
      {resolvedAnchor()}
      <Presence>
        <Show when={show() && rect()}>
          <Motion.div
            ref={menuRef!}
            {...propsFor("floating")}
            class={c`${styles.floating} ${propsFor("floating").class} ${className}`}
            initial={{ opacity: 0, y: -8 }}
            style={{
              top: `${rect()!.bottom}px`,
              ...(position === "bottom-left"
                ? {
                    right: `${window.innerWidth - rect()!.right}px`,
                  }
                : {
                    left: `${rect()!.left}px`,
                  }),
              width:
                position === "bottom" ? `${rect()!.width}px` : "fit-content",
              "max-height": `${window.innerHeight - rect()!.bottom}px`,
              ...propsFor("floating").style,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{ opacity: 0, y: -8 }}
            transition={{
              duration: 0.2,
              top: { duration: 0 },
              left: { duration: 0 },
              width: { duration: 0 },
            }}
          >
            {children}
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
}

export default FloatingWithAnchor;
