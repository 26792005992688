import { RecordTuple } from "record-tuple";

import UObject from "./UObject.mts";
import UProxy from "./UProxy.mts";

class PendingCache<Key = unknown, Value = unknown> {
  static cacheHit = Symbol("PendingCache.cacheHit");

  private cache = new Map<Key, Promise<Value>>();

  use(key: Key, create: () => Promise<Value>) {
    if (this.cache.has(key))
      return Object.assign(Promise.resolve(this.cache.get(key)!), {
        [PendingCache.cacheHit]: true,
      });

    const result = create();

    if (!(result instanceof Promise)) return result;

    this.cache.set(key, result);
    result.finally(() => {
      this.cache.delete(key);
    });

    return result;
  }

  wrap<T extends UObject.Any>(obj: T) {
    return UProxy.deepShim(
      obj,
      (action, path) =>
        (...args) =>
          this.use(
            RecordTuple.deep([
              // Prevent wrap from unexpectedly interfering with other keys by
              // including this.wrap reference as first item
              this.wrap,
              path,
              args,
            ]) as Key,
            () => action(...args),
          ),
    );
  }
}

export default PendingCache;
