import { ComponentProps, JSX } from "solid-js";
import c from "class-c";

import styles from "./Button.module.scss";

declare namespace Button {
  interface Props extends ComponentProps<"button"> {
    class?: string;
    variant:
      | "primary-hard"
      | "primary-soft"
      | "secondary"
      | "discouraged"
      | "text"
      | "text-primary"
      | "outlined"
      | "discouraged-dugout";
    size: "small" | "large" | "standard" | "full-width";
    children: JSX.Element;
  }
}

function Button({
  variant,
  size,
  class: className,
  children,
  ...props
}: D<Button.Props>) {
  return (
    <button
      type="button"
      {...props}
      class={c`
        ${c(styles)`
          button
          variant:${variant}
          size:${size}
        `}
        ${size === "small" && "text:small"}
        text:+
        ${className}
        
      `}
    >
      {children}
    </button>
  );
}

export default Button;
